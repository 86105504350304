<template>
  <v-responsive>
    <SubMenuLottowinMobile v-if="$vuetify.breakpoint.smAndDown"></SubMenuLottowinMobile>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>

          <SubMenuLottowin></SubMenuLottowin>
          
              <v-row class="my-0 mx-0" style="background: #fff">
                <v-col>
                     <GlobalSideBannerBottom></GlobalSideBannerBottom>
                </v-col>
              </v-row>

        </v-col>
        <v-col cols="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown? 'mt-0 pt-0':''">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
          <v-divider></v-divider>
          </v-container>

          <v-row class="my-3 mx-0" no-gutters style="background: #fff">
            <v-col>
                  <GlobalSubBannerTop></GlobalSubBannerTop>
            </v-col>
          </v-row>

          
          <v-sheet min-height="53vh">
            <!--  -->

            
          <v-responsive class="mt-10 mb-5">
              <h3>당첨금 지급장소</h3>

<div class="info_broadcast">
                <p>동행복권6/45의 추첨방송은 공정하고 투명한 절차를 거쳐 생방송으로 진행됩니다.</p>
                <div class="bx_extra tv">
                    <ul class="list">
                        <li><strong>방송시간</strong>매주 토요일 오후 8시 45분경</li>
                        <li><strong>방송채널</strong>MBC</li>
                    </ul>
                </div>
            </div>
          </v-responsive>

<iframe id="ifrm" name="ifrm"
  style="width:100%;min-height:350px;"
 src="https://m.dhlottery.co.kr/wenmedia/player.jsp?type=vod&sett=37&id=f4e05672-8251-4e2b-a6ce-48d54092cda5&width=100%25" frameborder="0" allowfullscreen="true"></iframe>



              <v-row class="my-3 mx-0" no-gutters style="background: #fff">
                <v-col>
                     <GlobalSubBannerBottom></GlobalSubBannerBottom>
                </v-col>
              </v-row>

          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "LottoWin",

  components: {
    //HelloWorld,
  },
  data: () => ({
    selectedItem: 0,
    breadcrumbs: [
      { text: "홈" },
      { text: "당첨결과 분석" },
      { text: "당첨영상 다시보기" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],
  }),
};
</script>
<style></style>
